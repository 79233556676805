<template>
    <div>
        <div v-if="!hiddenTitle">
            <span>表单名称：</span>
            <Input v-model.trim="formName" :maxlength="30" style="width: calc(50% - 100px)" placeholder="请输入表单名称，限30字以内"></Input>
        </div>
        <Divider style="margin: 14px 0" v-if="!hiddenTitle" />
        <div class="container">
            <div class="left">
                <div class="text">常用字段</div>
                <Draggable tag="ul" :group="{ name: 'form', pull: 'clone', put: false }" :list="compsList2" :sort="false">
                    <li v-for="(item, index) in compsList2" :key="index">
                        <div>{{ item.text }}</div>
                    </li>
                </Draggable>
                <div class="text">自定义</div>
                <Draggable tag="ul" :group="{ name: 'form', pull: 'clone', put: false }" :list="compsList" :sort="false">
                    <li v-for="(item, index) in compsList" :key="index">
                        <div>{{ item.text }}</div>
                    </li>
                </Draggable>
            </div>
            <Split v-model="split">
                <div class="main" slot="left">
                    <div class="mainContainer">
                        <Draggable @change="changeItem" group="form" tag="Form" class="mainForm" :list="mainList" :animation="200">
                            <div v-if="mainList.length == 0" class="noMainList">从左侧拖拽来添加表单</div>
                            <template v-else>
                                <div v-for="(item, index) in mainList" :key="index" @click.stop="handleFormItemClick(item, index)" :class="clickCurrentIndex == index ? 'form-item-active' : ''">
                                    <div v-if="item.type != 'Radio' && item.type != 'Checkbox' && item.type != 'File' && item.type != 'Image' && item.type != 'Video'" style="margin-bottom: 6px">
                                        <span class="itemRequired" v-if="item.required"></span>
                                        {{ index + 1 }}.{{ item.title }}
                                    </div>
                                    <div v-else style="margin-bottom: 6px">
                                        <span class="itemRequired" v-if="item.required"></span>
                                        {{ index + 1 }}.{{ item.title }}
                                        <span v-if="item.placeholder" style="color: #aaa">({{ item.placeholder }})</span>
                                    </div>
                                    <FormItem>
                                        <!-- 单选 -->
                                        <RadioGroup v-if="item.type == 'Radio'" :vertical="item.vertical" class="radio" :value="9999999999">
                                            <Radio :label="items.label" :disabled="true" v-for="(items, indexs) in item.optionList" :key="indexs">
                                                {{ items.label }}
                                                <span style="color: red">{{ item.linkAgeArr && item.linkAgeArr.length > 0 ? actionFn(item, items) : "" }}</span>
                                            </Radio>
                                        </RadioGroup>
                                        <!-- 多选框 -->
                                        <CheckboxGroup v-model="item.value" v-if="item.type == 'Checkbox'">
                                            <Checkbox
                                                :style="{
                                                    display: item.vertical ? 'block' : 'inline-block',
                                                }"
                                                :label="items.value"
                                                v-for="(items, indexs) in item.optionList"
                                                :key="indexs"
                                                :disabled="true"
                                            >
                                                {{ items.label }}
                                                <span style="color: red">{{ item.linkAgeArr && item.linkAgeArr.length > 0 ? actionFn(item, items) : "" }}</span>
                                            </Checkbox>
                                        </CheckboxGroup>
                                        <!-- 下拉选择器 -->
                                        <Select v-model="item.value" v-if="item.type == 'Select'" :multiple="item.multiple" :filterable="item.search" :placeholder="item.placeholder">
                                            <Option v-for="(items, indexs) in item.optionList" :value="items.value" :key="indexs">
                                                {{ items.label }}
                                                <span style="color: red">{{ item.linkAgeArr && item.linkAgeArr.length > 0 ? actionFn(item, items) : "" }}</span>
                                            </Option>
                                        </Select>
                                        <!-- 单行输入框 -->
                                        <Input v-model="item.value" :placeholder="item.placeholder" v-if="item.type == 'Input'" :disabled="true" />
                                        <!-- 多行输入框 -->
                                        <Input v-model="item.value" :disabled="true" :placeholder="item.placeholder" type="textarea" v-if="item.type == 'Textarea'"></Input>
                                        <!-- 数字 -->
                                        <InputNumber
                                            :disabled="true"
                                            :formatter="item.unit ? val => `${val}${item.unit}` : val => val"
                                            :parser="item.unit ? val => val.replace(item.unit, '') : val => val"
                                            :max="item.max"
                                            :min="item.min"
                                            v-model="item.min"
                                            :placeholder="item.placeholder"
                                            style="width: 200px"
                                            :step="item.step"
                                            :precision="item.precision"
                                            v-if="item.type == 'InputNumber'"
                                        ></InputNumber>
                                        <!-- 日期 -->
                                        <DatePicker type="date" :disabled="true" :placeholder="item.placeholder" v-model="item.value" v-if="item.type == 'DatePicker'"></DatePicker>
                                        <!-- 时间 -->
                                        <TimePicker type="time" :disabled="true" :placeholder="item.placeholder" v-model="item.value" v-if="item.type == 'TimePicker'"></TimePicker>
                                        <!-- 附件 -->
                                        <Upload :disabled="true" :multiple="item.multiple" type="drag" :max-size="item.max * 1024" action="" v-if="item.type == 'File'">
                                            <div style="padding: 20px 0">
                                                <Icon type="ios-cloud-upload" size="52" style="color: #3399ff"></Icon>
                                                <p>点击或推拽上传文件</p>
                                            </div>
                                        </Upload>
                                        <!-- 图片 -->
                                        <Upload :disabled="true" v-if="item.type == 'Image'" :multiple="item.multiple" action="" style="display: inline-block; width: 58px">
                                            <div style="width: 58px; height: 58px; line-height: 58px; text-align: center; border: 1px dashed #dcdee2; border-radius: 4px">
                                                <Icon type="ios-camera" size="20"></Icon>
                                            </div>
                                        </Upload>
                                        <!-- 视频 -->
                                        <div style="width: 80px; height: 80px; line-height: 80px; border: 1px dashed rgb(220, 222, 226); border-radius: 4px" v-if="item.type == 'Video'">
                                            <Icon type="md-add" size="80" style="color: #bbb"></Icon>
                                        </div>
                                        <!-- 级联选择器 -->
                                        <Cascader disabled v-if="item.type == 'Cascader'" filterable :placeholder="item.placeholder"></Cascader>

                                        <!-- 区市区街道选择器 -->
                                        <Input v-model="item.value" :disabled="true" :placeholder="item.placeholder" type="ZoneSelect" v-if="item.type == 'ZoneSelect'"></Input>
                                    </FormItem>
                                    <div class="modal">
                                        <!-- 复制/删除按钮 -->
                                        <div v-if="clickCurrentIndex == index" class="iconFormItem">
                                            <Icon type="ios-copy-outline" @click="copyRow(index)" />
                                            |
                                            <Icon type="ios-trash-outline" @click="deleteRow(index)" />
                                        </div>
                                    </div>
                                </div>
                            </template>
                        </Draggable>
                    </div>
                </div>
                <div class="right" slot="right">
                    <Tabs value="one">
                        <TabPane label="表单属性配置" name="one">
                            <component :is="rightComponentId" :rightData="rightData" :mainList="mainList" :clickCurrentIndex="clickCurrentIndex" @changeItemInfo="changeItemInfo"></component>
                        </TabPane>
                    </Tabs>
                </div>
            </Split>
        </div>
    </div>
</template>

<script>
import Draggable from "vuedraggable"
import RadioCom from "@/views/form/childrens/addChangeFormChildrens/RadioCom"
import CheckboxCom from "@/views/form/childrens/addChangeFormChildrens/CheckboxCom"
import InputCom from "@/views/form/childrens/addChangeFormChildrens/InputCom"
import SelectCom from "@/views/form/childrens/addChangeFormChildrens/SelectCom"
import TextareaCom from "@/views/form/childrens/addChangeFormChildrens/TextareaCom"
import NumberCom from "@/views/form/childrens/addChangeFormChildrens/NumberCom"
import DatePickerCom from "@/views/form/childrens/addChangeFormChildrens/DatePickerCom"
import TimePickerCom from "@/views/form/childrens/addChangeFormChildrens/TimePickerCom"
import FileCom from "@/views/form/childrens/addChangeFormChildrens/FileCom"
import ImageCom from "@/views/form/childrens/addChangeFormChildrens/ImageCom"
import VideoCom from "@/views/form/childrens/addChangeFormChildrens/VideoCom"
import CascaderCom from "@/views/form/childrens/addChangeFormChildrens/CascaderCom"
import ZoneSelectCom from "@/views/form/childrens/addChangeFormChildrens/ZoneSelectCom"
export default {
    props: ["hiddenTitle", "formDetailVo2", "disabledForm"],
    data() {
        return {
            split: 0.52,
            // 表单名称
            formName: "",
            //左侧组件
            compsList: [
                {
                    type: "Radio",
                    title: "单选",
                    label: "radio",
                    text: "单选",
                    value: "",
                    required: false,
                    vertical: false,
                    placeholder: "",
                    rightCom: "RadioCom",
                    optionList: [
                        //选项
                        {
                            label: "选项1",
                            person: "",
                        },
                        {
                            label: "选项2",
                            person: "",
                        },
                    ],
                    keyValue: [
                        //此数组为了保存提交的时候，根据字段获取每个组件的字段值
                        {
                            label: "标题",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "选项设置",
                            value: "vertical",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                    linkAgeArr: [],
                },
                {
                    type: "Checkbox",
                    title: "多选",
                    label: "checkbox",
                    text: "多选",
                    value: [],
                    vertical: false, //换行显示
                    required: false,
                    rightCom: "CheckboxCom",
                    optionList: [
                        //选项
                        {
                            label: "选项1",
                            person: "",
                        },
                        {
                            label: "选项2",
                            person: "",
                        },
                    ],
                    linkAgeArr: [],
                    keyValue: [
                        {
                            label: "标题",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "选项设置",
                            value: "vertical",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                },
                {
                    type: "Select",
                    title: "下拉选择",
                    label: "selected",
                    value: "",
                    required: false,
                    multiple: false, //多选
                    search: false,
                    text: "下拉选择",
                    placeholder: "",
                    rightCom: "SelectCom",
                    optionList: [],
                    linkAgeArr: [],
                    keyValue: [
                        {
                            label: "标题",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                        {
                            label: "可多选",
                            value: "multiple",
                        },
                        {
                            label: "可搜索",
                            value: "search",
                        },
                    ],
                },
                {
                    type: "Input",
                    title: "填空",
                    label: "text",
                    text: "填空",
                    value: "",
                    required: false,
                    ruleType: "", //校验类型
                    placeholder: "",
                    rightCom: "InputCom",
                    keyValue: [
                        {
                            label: "标题",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "校验类型",
                            value: "ruleType",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                },
                {
                    type: "Textarea",
                    title: "段落内容",
                    label: "textarea",
                    value: "",
                    text: "段落内容",
                    required: false,
                    placeholder: "",
                    rightCom: "TextareaCom",
                    keyValue: [
                        {
                            label: "标题",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                },

                {
                    type: "InputNumber",
                    title: "数字",
                    value: 1,
                    required: false,
                    min: 1,
                    max: 99999,
                    step: 1,
                    precision: 0,
                    label: "number",
                    placeholder: "",
                    text: "数字",
                    unit: "",
                    rightCom: "NumberCom",
                    keyValue: [
                        {
                            label: "标题",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "最小值",
                            value: "min",
                        },
                        {
                            label: "最大值",
                            value: "max",
                        },
                        {
                            label: "步长",
                            value: "step",
                        },
                        {
                            label: "小数位",
                            value: "precision",
                        },
                        {
                            label: "单位",
                            value: "unit",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                },
                {
                    type: "DatePicker",
                    title: "日期",
                    value: "",
                    required: false,
                    nowDate: false,
                    label: "date",
                    text: "日期",
                    rightCom: "DatePickerCom",
                    keyValue: [
                        {
                            label: "标题",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "默认当前日期",
                            value: "nowDate",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                },
                {
                    type: "TimePicker",
                    title: "时间",
                    value: "",
                    required: false,
                    label: "time",
                    text: "时间",
                    nowTime: false,
                    rightCom: "TimePickerCom",
                    keyValue: [
                        {
                            label: "标题",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "默认当前时间",
                            value: "nowTime",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                },
                {
                    type: "Image",
                    title: "图片",
                    action: "",
                    required: false,
                    limit: 10,
                    max: 1,
                    multiple: "",
                    secret: "", //私密情况
                    label: "image",
                    text: "图片",
                    rightCom: "ImageCom",
                    keyValue: [
                        {
                            label: "标题",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "是否多选",
                            value: "multiple",
                        },
                        {
                            label: "最多上传个数",
                            value: "max",
                        },
                        {
                            label: "限制大小",
                            value: "limit",
                        },
                        {
                            label: "秘密情况",
                            value: "secret",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                },
                {
                    type: "Video",
                    title: "视频",
                    action: "",
                    required: false,
                    limit: 25,
                    max: 9,
                    multiple: "",
                    secret: "", //私密情况
                    label: "video",
                    text: "视频",
                    rightCom: "VideoCom",
                    keyValue: [
                        {
                            label: "标题",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "是否多选",
                            value: "multiple",
                        },
                        {
                            label: "最多上传个数",
                            value: "max",
                        },
                        {
                            label: "限制大小",
                            value: "limit",
                        },
                        {
                            label: "秘密情况",
                            value: "secret",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                },
            ],
            compsList2: [
                {
                    type: "Input",
                    title: "请填写您的姓名",
                    label: "text",
                    text: "姓名",
                    value: "",
                    required: false,
                    ruleType: "", //校验类型
                    placeholder: "",
                    rightCom: "InputCom",
                    keyValue: [
                        {
                            label: "姓名",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "校验类型",
                            value: "ruleType",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                },
                {
                    type: "Input",
                    title: "请填写您的手机号",
                    label: "text",
                    text: "手机号",
                    value: "",
                    required: false,
                    ruleType: "2", //校验类型
                    placeholder: "",
                    rightCom: "InputCom",
                    list: "",
                    valiteText: "",
                    textType:'phone',
                    keyValue: [
                        {
                            label: "手机号",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "校验类型",
                            value: "ruleType",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                        {
                            label: "设置需过滤的手机号清单",
                            value: "list",
                        },
                        {
                            label: "过滤提示说明",
                            value: "valiteText",
                        },
                        {
                            label: "类型",
                            value: "mobile",
                        },
                        {
                            label: "限制号码",
                            value: "validateMobile",
                        },
                    ],
                },
                {
                    type: "Input",
                    title: "请填写您的身份证号",
                    label: "text",
                    text: "身份证号",
                    value: "",
                    required: false,
                    ruleType: "1", //校验类型
                    placeholder: "",
                    rightCom: "InputCom",
                    list: "",
                    valiteText: "",
                    textType:'idCard',
                    keyValue: [
                        {
                            label: "身份证号",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "校验类型",
                            value: "ruleType",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                        {
                            label: "设置需过滤的身份证号清单(换行设置多个)",
                            value: "list",
                        },
                        {
                            label: "过滤提示说明",
                            value: "valiteText",
                        },
                        {
                            label: "类型",
                            value: "id_card",
                        },
                        {
                            label: "限制手机号",
                            value: "validateIdNum",
                        },
                    ],
                },
                {
                    type: "Cascader",
                    title: "请填写您在当前社区现住地址",
                    label: "cascader",
                    value: "",
                    text: "现住地址",
                    required: false,
                    placeholder: "请填写小区楼栋房号/乡村组门牌号",
                    rightCom: "CascaderCom",
                    keyValue: [
                        {
                            label: "标题",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                },
                {
                    type: "Cascader",
                    title: "请填写您的户籍地址",
                    label: "cascader",
                    value: "",
                    text: "户籍地址",
                    required: false,
                    placeholder: "请填写小区楼栋房号/乡村组门牌号",
                    rightCom: "CascaderCom",
                    keyValue: [
                        {
                            label: "标题",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                },
                {
                    type: "ZoneSelect",
                    title: "所属地区",
                    label: "ZoneSelect",
                    value: "",
                    text: "所属地区",
                    required: false,
                    placeholder: "请选择省市区",
                    rightCom: "ZoneSelectCom",
                    keyValue: [
                        {
                            label: "标题",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                },

                {
                    type: "Radio",
                    title: "请选择您的性别",
                    label: "radio",
                    text: "性别",
                    value: "",
                    required: false,
                    vertical: false,
                    placeholder: "",
                    rightCom: "RadioCom",
                    optionList: [
                        //选项
                        {
                            label: "男",
                            person: "",
                        },
                        {
                            label: "女",
                            person: "",
                        },
                    ],
                    keyValue: [
                        //此数组为了保存提交的时候，根据字段获取每个组件的字段值
                        {
                            label: "标题",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "选项设置",
                            value: "vertical",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                    linkAgeArr: [],
                },
                {
                    type: "Radio",
                    title: "请选择您的婚姻状况",
                    label: "radio",
                    text: "婚姻状况",
                    value: "",
                    required: false,
                    vertical: false,
                    placeholder: "",
                    rightCom: "RadioCom",
                    optionList: [
                        //选项
                        {
                            label: "未婚",
                            person: "",
                        },
                        {
                            label: "已婚",
                            person: "",
                        },
                        {
                            label: "离异",
                            person: "",
                        },
                        {
                            label: "丧偶",
                            person: "",
                        },
                    ],
                    keyValue: [
                        //此数组为了保存提交的时候，根据字段获取每个组件的字段值
                        {
                            label: "标题",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "选项设置",
                            value: "vertical",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                    linkAgeArr: [],
                },
                {
                    type: "Input",
                    title: "请填写您的工作单位",
                    label: "text",
                    text: "工作单位",
                    value: "",
                    required: false,
                    ruleType: "", //校验类型
                    placeholder: "",
                    rightCom: "InputCom",
                    keyValue: [
                        {
                            label: "工作单位",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "校验类型",
                            value: "ruleType",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                },
                {
                    type: "Input",
                    title: "请选择您所属的民族",
                    label: "text",
                    text: "民族",
                    value: "",
                    required: false,
                    ruleType: "", //校验类型
                    placeholder: "",
                    rightCom: "InputCom",
                    keyValue: [
                        {
                            label: "民族",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "校验类型",
                            value: "ruleType",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                },
                {
                    type: "DatePicker",
                    title: "请选择您的出生日期",
                    value: "",
                    required: false,
                    label: "date",
                    text: "出生日期",
                    nowTime: false,
                    rightCom: "TimePickerCom",
                    keyValue: [
                        {
                            label: "出生日期",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "默认当前时间",
                            value: "nowTime",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                },
                {
                    type: "Radio",
                    title: "请选择您的政治面貌",
                    label: "radio",
                    text: "政治面貌",
                    value: "",
                    required: false,
                    vertical: false,
                    placeholder: "",
                    rightCom: "RadioCom",
                    optionList: [
                        //选项
                        {
                            label: "中共党员",
                            person: "",
                        },
                        {
                            label: "团员",
                            person: "",
                        },
                        {
                            label: "群众",
                            person: "",
                        },
                        {
                            label: "其他",
                            person: "",
                        },
                    ],
                    keyValue: [
                        //此数组为了保存提交的时候，根据字段获取每个组件的字段值
                        {
                            label: "政治面貌",
                            value: "title",
                        },
                        {
                            label: "填写说明",
                            value: "placeholder",
                        },
                        {
                            label: "选项设置",
                            value: "vertical",
                        },
                        {
                            label: "必填",
                            value: "required",
                        },
                    ],
                    linkAgeArr: [],
                },
                // {
                //   type: "Radio",
                //   title: "请选择您的新冠疫苗接种情况",
                //   label: "radio",
                //   text: "新冠疫苗接种情况",
                //   value: "",
                //   required: false,
                //   vertical: false,
                //   placeholder: "",
                //   rightCom: "RadioCom",
                //   optionList: [
                //     //选项
                //     {
                //       label: "未接种",
                //       person: "",
                //     },
                //     {
                //       label: "已接种第一针",
                //       person: "",
                //     },
                //     {
                //       label: "已接种第二针",
                //       person: "",
                //     },
                //     {
                //       label: "已完成全程接种",
                //       person: "",
                //     },
                //   ],
                //   keyValue: [
                //     //此数组为了保存提交的时候，根据字段获取每个组件的字段值
                //     {
                //       label: "标题",
                //       value: "title",
                //     },
                //     {
                //       label: "填写说明",
                //       value: "placeholder",
                //     },
                //     {
                //       label: "选项设置",
                //       value: "vertical",
                //     },
                //     {
                //       label: "必填",
                //       value: "required",
                //     },
                //   ],
                //   linkAgeArr: [],
                // },
                // {
                //   type: "Radio",
                //   title: "请选择您的接种品牌",
                //   label: "radio",
                //   text: "接种品牌",
                //   value: "",
                //   required: false,
                //   vertical: false,
                //   placeholder: "",
                //   rightCom: "RadioCom",
                //   optionList: [
                //     //选项
                //     {
                //       label: "康希诺生物（1针）",
                //       person: "",
                //     },
                //     {
                //       label: "北京生物（2针）",
                //       person: "",
                //     },
                //     {
                //       label: "武汉生物（2针）",
                //       person: "",
                //     },
                //     {
                //       label: "科兴中维（2针）",
                //       person: "",
                //     },
                //     {
                //       label: "安徽智飞（3针）",
                //       person: "",
                //     },
                //     {
                //       label: "其他",
                //       person: "",
                //     },
                //   ],
                //   keyValue: [
                //     //此数组为了保存提交的时候，根据字段获取每个组件的字段值
                //     {
                //       label: "标题",
                //       value: "title",
                //     },
                //     {
                //       label: "填写说明",
                //       value: "placeholder",
                //     },
                //     {
                //       label: "选项设置",
                //       value: "vertical",
                //     },
                //     {
                //       label: "必填",
                //       value: "required",
                //     },
                //   ],
                //   linkAgeArr: [],
                // },
                // {
                //   type: "Radio",
                //   title: "请选择您的核酸检测结果",
                //   label: "radio",
                //   text: "核酸检测结果",
                //   value: "",
                //   required: false,
                //   vertical: false,
                //   placeholder: "",
                //   rightCom: "RadioCom",
                //   optionList: [
                //     //选项
                //     {
                //       label: "阴性（正常）",
                //       person: "",
                //     },
                //     {
                //       label: "阳性",
                //       person: "",
                //     }
                //   ],
                //   keyValue: [
                //     //此数组为了保存提交的时候，根据字段获取每个组件的字段值
                //     {
                //       label: "标题",
                //       value: "title",
                //     },
                //     {
                //       label: "填写说明",
                //       value: "placeholder",
                //     },
                //     {
                //       label: "选项设置",
                //       value: "vertical",
                //     },
                //     {
                //       label: "必填",
                //       value: "required",
                //     },
                //   ],
                //   linkAgeArr: [],
                // },
                //  {
                //   type: "DatePicker",
                //   title: "请选择您的核酸检测时间",
                //   value: "",
                //   required: false,
                //   label: "date",
                //   text: "核酸检测时间",
                //   nowTime: false,
                //   rightCom: "TimePickerCom",
                //   keyValue: [
                //     {
                //       label: "标题",
                //       value: "title",
                //     },
                //     {
                //       label: "填写说明",
                //       value: "placeholder",
                //     },
                //     {
                //       label: "默认当前时间",
                //       value: "nowTime",
                //     },
                //     {
                //       label: "必填",
                //       value: "required",
                //     },
                //   ],
                // },
            ],
            //   中间表单
            mainList: [],
            rightData: {}, //右侧表单数据
            clickCurrentIndex: 0,
            rightComponentId: "",
            templateType: "1",
            publicType: "1",
        }
    },
    methods: {
        actionFn(item, subItem) {
            let text = ""
            item.linkAgeArr.map(k => {
                this.mainList.find((e, index) => {
                    if (e.id == k.title && subItem.id == k.value) {
                        if (k.result == "display") {
                            text += "显示第" + (index + 1) + "题;"
                        } else if (k.result == "hidden") {
                            text += "隐藏第" + (index + 1) + "题;"
                        } else {
                            text += "必填第" + (index + 1) + "题;"
                        }
                    }
                })
            })
            if (text) {
                text = "(" + text.substring(0, text.length - 1) + ")"
            }
            return text
        },
        // 活动升级 =》暴露出表单提交的数据
        exportFormData() {
            let interactionFormVos = this.mainList.map((item, index) => {
                return {
                    authentication: item.ruleType,
                    content: item.label, //内容
                    fieldCode: item.label + index, //字段编码
                    fieldId: item.id, //字段ID
                    fieldParamVos: item.keyValue.map(items => {
                        if ((items.value == "validateMobile" || items.value == "validateIdNum") && item.optionList && item.optionList.length) {
                            console.log("如果存在有数据的情况，并且是有手机和身份证",item.optionList)
                            return {
                                fieldId: this.$core.randomString(),
                                id: this.$core.randomString(),
                                paramCode: items.value,
                                paramName: items.label,
                                paramValue: (() => {
                                    //选项联动动作
                                    let paramValuelist = []
                                    item.optionList.map(valueItem => {
                                        paramValuelist.push(valueItem.label)
                                    })
                                    return paramValuelist.join(",")
                                })(),
                            }
                        } else {
                            return {
                                fieldId: this.$core.randomString(),
                                id: this.$core.randomString(),
                                paramCode: items.value,
                                paramName: items.label,
                                paramValue: item[items.value],
                            }
                        }
                        //选项配置
                    }),
                    formType: item.label,
                    list: item.textType && item.textType != '' ? [] : item.optionList
                        ? item.optionList.map(items => {
                              return {
                                  field: items.id,
                                  inputType: item.type.toLowerCase(),
                                  linkageAction: (() => {
                                      //选项联动动作
                                      if (item.linkAgeArr && item.linkAgeArr.length > 0) {
                                          let result = ""
                                          item.linkAgeArr.map(subItem => {
                                              if (subItem.value == items.id) {
                                                  //根据联动列表的选项值来匹配
                                                  result = subItem.result
                                              }
                                          })
                                          return result
                                      } else {
                                          return ""
                                      }
                                  })(),
                                  linkageFieldId: (() => {
                                      //题目ID
                                      if (item.linkAgeArr && item.linkAgeArr.length > 0) {
                                          let titleId = []
                                          item.linkAgeArr.map(subItem => {
                                              if (subItem.value == items.id) {
                                                  //根据联动列表的选项值来匹配
                                                  titleId.push(subItem.title)
                                              }
                                          })
                                          return titleId.join(",")
                                      } else {
                                          return ""
                                      }
                                  })(),
                                  person: items.person, //人数限制
                                  selected: "", //是否选中
                                  sequence: items.value, //序号
                                  title: items.label, //选项label
                              }
                          })
                        : [],
                    relevance: "", //关联序号 单选不在其中,
                    required: item.required ? "1" : "0",
                    sequence: index + 1,
                    topic: item.title,
                    classField: item.classField,
                    tbField: item.tbField,
                }
            })
            console.log("暴露出去", interactionFormVos)
            let result = {
                createName: parent.vue.loginInfo.userinfo.realName,
                custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                endFlag: "1", //是否最后一个表单 1：是 2：否
                formContent: this.formName, //表单内容
                formName: this.formName,
                formTitle: this.formName,
                id: this.$core.randomString(),
                interactionFormVos: interactionFormVos,
                mergeData: "2", //是否把保存的数据合并到父表单 1：是 2：否"
                oemCode: parent.vue.oemInfo.oemCode,
                orgCode: parent.vue.loginInfo.userinfo.orgCode,
                projectCode: this.$core.randomString(),
                templateType: "", //模板类型
            }
            return result
        },
        // 从活动修改传进来的表单数据
        activityDataFn(data) {
            if (data.interactionFormVos) {
                this.formName = data.formTitle
                //根据响应数据，组织mainList
                this.mainList = data.interactionFormVos.map(item => {
                    let temp = {} //mainList的key和value
                    let rightCom = "" //右侧组件
                    let type = ""
                    switch (item.content) {
                        case "radio":
                        case "sex":
                        case "political":
                            rightCom = "RadioCom"
                            type = "Radio"
                            break
                        case "checkbox":
                            rightCom = "CheckboxCom"
                            type = "Checkbox"
                            break
                        case "selected":
                            rightCom = "SelectCom"
                            type = "Select"
                            break
                        case "text":
                        case "userName":
                        case "mobile":
                        case "idNum":
                        case "addr":
                        case "marital":
                        case "postalCode":
                        case "workUnits":
                        case "national":
                        case "vaccines":
                        case "brand":
                        case "result":
                            rightCom = "InputCom"
                            type = "Input"
                            break
                        case "textarea":
                            rightCom = "TextareaCom"
                            type = "Textarea"
                            break
                        case "number":
                            rightCom = "NumberCom"
                            type = "InputNumber"
                            break
                        case "time":
                        case "brithday":
                        case "testing":
                            rightCom = "TimePickerCom"
                            type = "TimePicker"
                            break
                        case "date":
                            rightCom = "DatePickerCom"
                            type = "DatePicker"
                            break
                        case "file":
                            rightCom = "FileCom"
                            type = "File"
                            break
                        case "image":
                            rightCom = "ImageCom"
                            type = "Image"
                            break
                        case "video":
                            rightCom = "VideoCom"
                            type = "Video"
                            break
                        case "cascader":
                            rightCom = "CascaderCom"
                            type = "Cascader"
                            break
                        case "ZoneSelect":
                            rightCom = "ZoneSelectCom"
                            type = "Input"
                            break
                    }
                    let keyValue = item.fieldParamVos.map(items => {
                        // 把字符串形式的boolean转Boolean
                        if (
                            items.paramCode == "vertical" ||
                            items.paramCode == "required" ||
                            items.paramCode == "nowTime" ||
                            items.paramCode == "nowDate" ||
                            items.paramCode == "search" ||
                            items.paramCode == "multiple"
                        ) {
                            items.paramValue = items.paramValue == "true" ? true : items.paramValue == "false" ? false : ""
                        }
                        if (items.paramCode == "min" || items.paramCode == "max" || items.paramCode == "step" || items.paramCode == "precision") {
                            items.paramValue = Number(items.paramValue)
                        }
                        temp[items.paramCode] = items.paramValue
                        return {
                            value: items.paramCode,
                            label: items.paramName,
                        }
                    })
                    return {
                        classField: item.classField,
                        tbField: item.tbField,
                        id: item.fieldId,
                        type: type,
                        ...temp,
                        ruleType: item.authentication,
                        label: item.content,
                        keyValue: keyValue,
                        label: item.content,
                        rightCom: rightCom,
                        optionList: item.list
                            ? item.list.map(items => {
                                  return {
                                      value: items.sequence,
                                      label: items.title,
                                      person: items.person,
                                      id: items.field,
                                  }
                              })
                            : [],
                        linkAgeArr: (() => {
                            //组织联动设置
                            let arr = []
                            for (let k in item.list) {
                                if (item.list[k].linkageFieldId) {
                                    let linkageFieldId = item.list[k].linkageFieldId.split(",")
                                    if (linkageFieldId.length > 1) {
                                        linkageFieldId.map((subItem, subIndex) => {
                                            arr.push({
                                                value: item.list[k].field,
                                                title: linkageFieldId[subIndex],
                                                result: item.list[k].linkageAction,
                                            })
                                        })
                                    } else {
                                        arr.push({
                                            value: item.list[k].field,
                                            title: item.list[k].linkageFieldId,
                                            result: item.list[k].linkageAction,
                                        })
                                    }
                                }
                            }
                            return arr
                        })(),
                    }
                })

                // 自动选中第一项
                if (this.mainList[0]) {
                    this.handleFormItemClick(this.mainList[0], "0")
                }
            }
        },
        // 保存
        save() {
            if (this.formName == "") {
                this.$Message.error({
                    background: true,
                    content: "请填写表单名称",
                })
                return
            }
            if (this.mainList.length == 0) {
                this.$Message.error({
                    background: true,
                    content: "请先添加表单再保存",
                })
                return
            }
            this.$Modal.confirm({
                title: "模板同步设置",
                loading: true,
                width: "430",
                onOk: () => {
                    let interactionFormVos = this.mainList.map((item, index) => {
                        return {
                            classField: item.classField,
                            tbField: item.tbField,
                            authentication: item.ruleType,
                            content: item.label, //内容
                            fieldCode: item.label + index, //字段编码
                            fieldId: item.id, //字段ID
                            fieldParamVos: item.keyValue.map(items => {
                                //选项配置
                                return {
                                    fieldId: this.$core.randomString(),
                                    id: this.$core.randomString(),
                                    paramCode: items.value,
                                    paramName: items.label,
                                    paramValue: item[items.value],
                                }
                            }),
                            formType: item.label,
                            list: item.optionList
                                ? item.optionList.map(items => {
                                      return {
                                          field: items.id,
                                          inputType: item.type.toLowerCase(),
                                          linkageAction: (() => {
                                              //选项联动动作
                                              if (item.linkAgeArr && item.linkAgeArr.length > 0) {
                                                  let result = ""
                                                  item.linkAgeArr.map(subItem => {
                                                      if (subItem.value == items.id) {
                                                          //根据联动列表的选项值来匹配
                                                          result = subItem.result
                                                      }
                                                  })
                                                  return result
                                              } else {
                                                  return ""
                                              }
                                          })(),
                                          linkageFieldId: (() => {
                                              //题目ID
                                              if (item.linkAgeArr && item.linkAgeArr.length > 0) {
                                                  let titleId = []
                                                  item.linkAgeArr.map(subItem => {
                                                      if (subItem.value == items.id) {
                                                          //根据联动列表的选项值来匹配
                                                          titleId.push(subItem.title)
                                                      }
                                                  })
                                                  return titleId.join(",")
                                              } else {
                                                  return ""
                                              }
                                          })(),
                                          person: items.person, //人数限制
                                          selected: "", //是否选中
                                          sequence: items.value, //序号
                                          title: items.label, //选项label
                                      }
                                  })
                                : [],
                            relevance: "", //关联序号 单选不在其中,
                            required: item.required ? "1" : "0",
                            sequence: index + 1,
                            topic: item.title,
                        }
                    })

                    if (Object.keys(interactionFormVos).length != 0 && interactionFormVos.length != 0) {
                        interactionFormVos.map(item => {
                            if (item.fieldParamVos.length != 0) {
                                let obj = {}
                                let isTrue = false
                                item.fieldParamVos.map(items => {
                                    if (items.paramCode != "fieldType") {
                                        switch (item.formType) {
                                            case "userName":
                                                obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "姓名", value: "userName" }
                                                isTrue = true
                                                break
                                            case "mobile":
                                                obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "手机号", value: "mobile" }
                                                isTrue = true
                                                break
                                            case "idNum":
                                                obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "身份证号", value: "idNum" }
                                                isTrue = true
                                                break
                                            case "addr":
                                                obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "地址", value: "addr" }
                                                isTrue = true
                                                break
                                            case "sex":
                                                obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "性别", value: "sex" }
                                                isTrue = true
                                                break
                                            case "age":
                                                obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "年龄", value: "age" }
                                                isTrue = true
                                                break
                                            case "marital":
                                                obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "婚姻状况", value: "marital" }
                                                isTrue = true
                                                break
                                            case "workUnits":
                                                obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "工作单位", value: "workUnits" }
                                                isTrue = true
                                                break
                                            case "national":
                                                obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "民族", value: "national" }
                                                isTrue = true
                                                break
                                            case "brithday":
                                                obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "出生日期", value: "brithday" }
                                                isTrue = true
                                                break
                                            case "political":
                                                obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "政治面貌", value: "political" }
                                                isTrue = true
                                                break
                                            case "vaccines":
                                                obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "新冠疫苗接种情况", value: "vaccines" }
                                                isTrue = true
                                                break
                                            case "brand":
                                                obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "接种品牌", value: "brand" }
                                                isTrue = true
                                                break
                                            case "testing":
                                                obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "核酸检测时间", value: "testing" }
                                                isTrue = true
                                                break
                                            case "result":
                                                obj = { fieldId: this.$core.randomString(), id: this.$core.randomString(), paramCode: "fieldType", paramName: "核酸检查结果", value: "result" }
                                                isTrue = true
                                                break
                                        }
                                    }
                                })

                                if (isTrue) {
                                    item.fieldParamVos.push(obj)
                                }
                            }
                        })
                    }

                    this.$post(
                        "/datamsg/api/pc/dynamicform/saveOrUpdateForm",
                        {
                            createName: parent.vue.loginInfo.userinfo.realName,
                            custGlobalId: parent.vue.loginInfo.userinfo.custGlobalId,
                            endFlag: "1", //是否最后一个表单 1：是 2：否
                            formContent: this.formName, //表单内容
                            formName: this.formName,
                            formTitle: this.formName,
                            id: this.$core.randomString(),
                            interactionFormVos: interactionFormVos,
                            mergeData: "2", //是否把保存的数据合并到父表单 1：是 2：否"
                            oemCode: parent.vue.oemInfo.oemCode,
                            orgCode: parent.vue.loginInfo.userinfo.orgCode,
                            projectCode: this.$core.randomString(),
                            templateType: this.templateType, //模板类型
                            publicType: this.templateType == "1" ? "0" : this.publicType,
                        },
                        { "Content-Type": "application/json" }
                    )
                        .then(res => {
                            this.$Message.destroy()
                            if (res.code == 200) {
                                this.$Modal.remove()
                                this.$Message.success({
                                    background: true,
                                    content: "保存成功",
                                })
                                this.$emit("saveSuccess")
                            } else {
                                this.$Modal.remove()
                                this.$Message.error({
                                    background: true,
                                    content: res.desc,
                                })
                            }
                        })
                        .catch(err => {
                            this.$Modal.remove()
                            this.$Message.destroy()
                            this.$Message.error({
                                background: true,
                                content: "保存失败,请联系管理员处理",
                            })
                        })
                },
                render: h => {
                    return h("div", [
                        h(
                            "RadioGroup",
                            {
                                props: {
                                    value: this.templateType,
                                },
                                on: {
                                    "on-change": val => {
                                        this.templateType = val
                                    },
                                },
                            },
                            [
                                h(
                                    "Radio",
                                    {
                                        props: {
                                            label: "1",
                                        },
                                    },
                                    "私有模板"
                                ),
                                h(
                                    "Radio",
                                    {
                                        props: {
                                            label: "2",
                                        },
                                    },
                                    "公开模板"
                                ),
                            ]
                        ),

                        h(
                            "div",
                            {
                                style: {
                                    display: this.templateType == "2" ? "inline-block" : "none",
                                    marginLeft: "6px",
                                },
                            },
                            [
                                h(
                                    "span",
                                    {
                                        style: {
                                            color: "#aaa",
                                        },
                                    },
                                    "公开类型："
                                ),
                                h(
                                    "RadioGroup",
                                    {
                                        props: {
                                            value: this.publicType,
                                        },
                                        on: {
                                            "on-change": val => {
                                                this.publicType = val
                                            },
                                        },
                                    },
                                    [
                                        h(
                                            "Radio",
                                            {
                                                props: {
                                                    label: "1",
                                                },
                                            },
                                            "机构"
                                        ),
                                        h(
                                            "Radio",
                                            {
                                                props: {
                                                    label: "2",
                                                },
                                            },
                                            "平台"
                                        ),
                                    ]
                                ),
                            ]
                        ),
                    ])
                },
            })
        },
        // 中间表单拷贝
        copyRow(index) {
            let arr = JSON.parse(JSON.stringify(this.mainList[index]))
            for (let k in arr) {
                if (k == "id") {
                    arr.id = this.$core.randomString()
                } else if (k == "optionList" && arr.optionList.length > 0) {
                    arr[k].map(item => {
                        item.id = this.$core.randomString()
                    })
                } else if (k == "tbField" || k == "classField") {
                    item.tbField = null
                    item.classField = null
                }
            }
            this.mainList.push({
                ...arr,
            })
        },
        // 中间表单删除
        deleteRow(index) {
            this.mainList.splice(index, 1)
            // 删除后默认选择后一个
            if (index != this.mainList.length) {
                this.handleFormItemClick(JSON.parse(JSON.stringify(this.mainList[index])), index)
            }
        },
        // 右侧数据改变，修改mainList
        changeItemInfo(item) {
            this.mainList.splice(this.clickCurrentIndex, 1, item)
        },
        //点击中间表单，改变右侧组件显示
        handleFormItemClick(item, index) {
            this.clickCurrentIndex = index
            this.rightComponentId = ""
            this.rightData = {}
            this.$nextTick(() => {
                this.rightComponentId = item.rightCom
                this.rightData = JSON.parse(JSON.stringify(item))
            })
        },
        changeItem(e) {
            if (e && e.added) {
                if (e.added.newIndex == 1 && this.mainList.length == 1) {
                    //此处为了解决推拽出现两个表单组件
                    e.added.newIndex = 0
                }
                this.clickCurrentIndex = e.added.newIndex
                this.mainList.splice(
                    e.added.newIndex,
                    1,
                    JSON.parse(
                        JSON.stringify({
                            ...e.added.element,
                            id: this.$core.randomString(),
                        })
                    )
                )
                if (this.mainList[e.added.newIndex].optionList && this.mainList[e.added.newIndex].optionList.length > 0) {
                    //代表单选和多选，此时动态添加id
                    this.mainList[e.added.newIndex].optionList.map(item => {
                        item.id = this.$core.randomString()
                    })
                }
                this.rightComponentId = ""
                this.$nextTick(() => {
                    this.rightComponentId = e.added.element.rightCom
                    // 深拷贝一行数据，如果不这样的话，会影响mainList中的json数据格式
                    this.rightData = JSON.parse(JSON.stringify(this.mainList[e.added.newIndex]))
                })
            }
        },
    },
    created() {
        console.log("addForm")
        if (this.formDetailVo2 && Object.keys(this.formDetailVo2).length != 0) {
            this.formId2 = this.formDetailVo2.id
            this.templateType = this.formDetailVo2.templateType
            this.activityDataFn(this.formDetailVo2)
        }
    },
    components: {
        Draggable,
        RadioCom,
        CheckboxCom,
        InputCom,
        SelectCom,
        TextareaCom,
        NumberCom,
        DatePickerCom,
        TimePickerCom,
        FileCom,
        ImageCom,
        VideoCom,
        CascaderCom,
        ZoneSelectCom,
    },
}
</script>
    
<style scoped lang='less'>
.container {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 185px);
    /deep/.left {
        width: 280px !important;
        height: 100%;
        overflow: auto;
        border: 1px solid #ccc;
        padding: 10px;
        .text {
            width: 100%;
            text-align: left;
            padding-left: 10px;
            font-size: 16px;
            padding: 5px 0 5px 10px;
            font-weight: bold;
        }
        ul {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            text-align: center;

            li {
                min-width: 80px;
                max-width: 90px;
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: center;
                box-sizing: border-box;
                padding: 0px 6px;
                min-height: 40px;
                margin: 5px;
                box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.5);
                border-radius: 4px;
                cursor: move;
                border: 1px solid #ccc;
                .title {
                    font-weight: bold;
                }
            }
        }
    }
    .main {
        margin: 0 10px;
        padding: 10px;
        flex: 1;
        height: 100%;
        overflow: auto;
        border: 1px solid #ccc;
        .mainContainer {
            height: 100%;
            .noMainList {
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
                text-align: center;
            }
            .ivu-form {
                height: 100%;
                // 必填
                .itemRequired {
                    &::before {
                        content: "*";
                        display: inline-block;
                        margin-right: 4px;
                        line-height: 1;
                        font-family: SimSun;
                        font-size: 14px;
                        color: #ed4014;
                    }
                }
                /deep/.ivu-form-item-content {
                    cursor: move;
                    input,
                    .ivu-select-selection {
                        background: white;
                        cursor: move;
                        position: relative;
                        padding: 0 20px;
                    }
                }

                /deep/.ivu-form-item {
                    position: relative;
                    cursor: move;
                }
                /* 表单项激活时 */
                .form-item-active {
                    position: relative;
                    .modal {
                        position: absolute;
                        left: -10px;
                        right: -10px;
                        top: -10px;
                        bottom: -10px;
                        border: 1px solid #409eff;
                        border-radius: 4px;
                        z-index: 999;
                        cursor: move;
                        .iconFormItem {
                            color: #409eff;
                            position: absolute;
                            right: 15px;
                            bottom: -14px;
                            height: 30px;
                            line-height: 30px;
                            border: 1px solid #409eff;
                            background: #fff;
                            border-radius: 25px;
                            z-index: 9;
                            .ivu-icon-ios-copy-outline {
                                font-size: 20px;
                                color: #409eff;
                                margin: 0 0 0 10px;
                                cursor: pointer;
                            }
                            .ivu-icon-ios-trash-outline {
                                font-size: 20px;
                                color: #f2a49d;
                                margin: 0 10px 0 0;
                                cursor: pointer;
                            }
                        }
                    }
                }
            }
            .radio {
                /deep/input {
                    width: 0;
                    height: 0;
                }
            }
        }
    }
    .right {
        padding: 5px 10px;
        // width: 360px;
        height: 100%;
        overflow: auto;
        border: 1px solid #ccc;
        /deep/.ivu-form-item {
            margin-bottom: 10px;
        }
    }
}

// 禁用样式
/deep/.ivu-input-number-disabled,
/deep/.ivu-input-number-input[disabled],
/deep/.ivu-input[disabled] {
    background-color: white;
    color: #515a6e;
    cursor: move;
}
/deep/.ivu-input[disabled],
fieldset[disabled] .ivu-input,
/deep/.ivu-select-disabled .ivu-select-selection,
/deep/.ivu-radio-disabled .ivu-radio-inner {
    background-color: #fff !important;
    color: #515a6e;
}
/deep/.ivu-radio-disabled .ivu-radio-inner,
/deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner,
/deep/.ivu-checkbox-disabled .ivu-checkbox-inner {
    border: 1px solid #dcdee2;
    background-color: #fff !important;
}
/deep/.ivu-checkbox-disabled.ivu-checkbox-checked .ivu-checkbox-inner:after,
/deep/.ivu-radio-disabled .ivu-radio-inner:after {
    border-color: #dcdee2;
    background-color: #fff !important;
}
/deep/.ivu-radio-disabled .ivu-radio-inner:after {
    background-color: #888;
    background-color: #fff !important;
}
</style>